import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../../components/global/layout/layout';
import Slice from '../../components/global/slice/slice';
import HeadingAndText from '../../components/page/heading-and-text/heading-and-text';
import ImageHeader from '../../components/page/image-header/image-header';
import { getHeaderImageSlice, getHeadingAndTextSlice } from '../../utils/slices';

export default ({ data }) => {
    return (
        <Layout title={data.prismicBlogPost.data.meta_title.text || data.prismicBlogPost.data.title.text}
                description={data.prismicBlogPost.data.meta_description.text || data.prismicBlogPost.data.description.text}
                image={data.prismicBlogPost.data.meta_image.url || data.prismicBlogPost.data.image.url}>
            <ImageHeader slice={getHeaderImageSlice(data.prismicBlogPost.data.image.url)}/>
            <div className="container mb-4">
                <div className="row">
                    <div className="col">
                        <small>Veröffentlicht am {data.prismicBlogPost.publish_date}</small>
                    </div>
                </div>
            </div>
            <HeadingAndText slice={getHeadingAndTextSlice(data.prismicBlogPost.data.title.text)}/>
          <Slice slices={data.prismicBlogPost.data.body}/>
        </Layout>
    )
}

export const pageQuery = graphql`
  query PostByUid($uid: String!) {
    prismicBlogPost(uid: {eq: $uid}) {
    publish_date: first_publication_date(formatString: "DD.MM.YYYY")
    data {
      meta_title {
        text
      }
      meta_image {
        url
      }
      meta_description {
        text
      }
      title {
        text
      }
      image {
        url
      }
      description {
        text
      }
      body {
        __typename
        ... on PrismicBlogPostBodyText {
          primary {
            text {
              html
            }
          }
        }
        ... on PrismicBlogPostBodyPersonenBereich {
          # primary {
          #   heading {
          #     text
          #   }
          # }
          items {
            text {
              html
              text
            }
            position {
              text
              raw
            }
            lastname {
              text
            }
            image {
              url
              alt
            }
            firstname {
              text
            }
            # email {
            #   text
            # }
            # phone {
            #   text
            # }
          }
        }
        ... on PrismicBlogPostBodyBild {
          primary {
            caption {
              html
            }
            image1 {
              url
            }
          }
        }
      }
    }
  }
  }
`
